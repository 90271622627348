import {
  HANDLE_USER_INPUT,
  FORM_VALIDATED,
  EMAIL_REGISTRATION_SUCCESSFUL,
  EMAIL_REGISTRATION_ERROR,
  PREFILL_REG_FORM,
  REQUEST_VERIFY_RECAPTCHA,
  VERIFY_RECAPTCHA_FAILURE, HANDLE_REGISTRATION_STEP, REQUEST_CREATE_USER, CREATE_USER_RECEIVED, CREATE_USER_FAILED
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    itemKey: "",
    partner: "sams-club"
  },
  registrationType: "email", // default to email
  userSignedIn: false,
  error: "",
  loading: false,
  samsClubStep: 0
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case REQUEST_CREATE_USER: {
      if (action.registrationType === "email") {
        state.user.email = action.userToCreate.email;
        state.user.first_name = action.userToCreate.first_name;
        state.user.last_name = action.userToCreate.last_name;
      }
      return {
        ...state,
        registrationType: action.registrationType,
        loading: true
      };
    }
    case CREATE_USER_RECEIVED: {
      state.user["id"] = action.data.id;
      state.user["displayName"] = action.data.displayName;
      state.user["email"] = action.data.identifier;
      if (state.registrationType === "google") {
        state.user["googleId"] = action.data.googleId;
      } else if (state.registrationType === "facebook") {
        state.user["facebookId"] = action.data.facebookId;
      }
      return { ...state, error: "", userSignedIn: true, loading: false };
    }
    case CREATE_USER_FAILED:
      return {
        ...state,
        error: action.error,
        userSignedIn: false,
        loading: false
      };
    case HANDLE_REGISTRATION_STEP:
      return { ...state, samsClubStep: (state.samsClubStep += 1) };
    case HANDLE_USER_INPUT:
      return {
        ...state,
        [action.key]: action.value,
        validated: false,
        error: null
      };
    case FORM_VALIDATED:
      return { ...state, validated: action.flag, error: null, loading: true };
    case EMAIL_REGISTRATION_SUCCESSFUL:
      return { ...state, error: null, loading: false };
    case EMAIL_REGISTRATION_ERROR:
      return {
        ...state,
        validated: false,
        error: action.error,
        loading: false
      };
    case PREFILL_REG_FORM:
      return {
        ...state,
        first_name: action.user.firstName,
        last_name: action.user.lastName,
        email: action.user.email
      };
    case REQUEST_VERIFY_RECAPTCHA:
      return { ...state, loading: true, error: null };
    case VERIFY_RECAPTCHA_FAILURE:
      return {
        ...state,
        validated: false,
        recaptchaVerified: false,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
};
