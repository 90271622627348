import {
  HANDLE_USER_INPUT,
  FORM_VALIDATED,
  EMAIL_REGISTRATION_SUCCESSFUL,
  EMAIL_REGISTRATION_ERROR,
  PREFILL_REG_FORM,
  REQUEST_VERIFY_RECAPTCHA,
  VERIFY_RECAPTCHA_FAILURE
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  validated: false,
  error: null,
  loading: false
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case HANDLE_USER_INPUT:
      return {
        ...state,
        [action.key]: action.value,
        validated: false,
        error: null
      };
    case FORM_VALIDATED:
      return { ...state, validated: action.flag, error: null, loading: true };
    case EMAIL_REGISTRATION_SUCCESSFUL:
      return { ...state, error: null, loading: false };
    case EMAIL_REGISTRATION_ERROR:
      return {
        ...state,
        validated: false,
        error: action.error,
        loading: false
      };
    case PREFILL_REG_FORM:
      return {
        ...state,
        first_name: action.user.firstName,
        last_name: action.user.lastName,
        email: action.user.email
      };
    case REQUEST_VERIFY_RECAPTCHA:
      return { ...state, loading: true, error: null };
    case VERIFY_RECAPTCHA_FAILURE:
      return {
        ...state,
        validated: false,
        recaptchaVerified: false,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
};
