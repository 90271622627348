import {
  SET_PARTNER, PROMO_USER_INPUT, PROMO_FORM_VALIDATED, SCROLL_TO_PROMO_FORM,
  SCROLLED_SUCCESS, REQUEST_SWEEPS_CONSENT, SWEEPS_CONSENT_SUCCESS,
  SWEEPS_CONSENT_FAILURE, RESET_PROMO_STATE
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  partner: 'organic',
  firstName: '',
  lastName: '',
  email: '',
  zipcode: '',
  birthYear: '',
  instagramHandle: '',
  twitterHandle: '',
  error: null,
  loading: false,
  scrollToPromoForm: false,
  sweepsConsentSuccess: false,
  dailyburnConsent: false,
  hollandAmericaConsent: false
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case SET_PARTNER:
      return { ...state, partner: action.partner };
    case PROMO_USER_INPUT:
      return { ...state, [action.key]: action.value, validated: false, error: null };
    case PROMO_FORM_VALIDATED:
      return { ...state, validated: action.flag, error: null, loading: true };
    case SCROLL_TO_PROMO_FORM:
      return { ...state, scrollToPromoForm: true };
    case SCROLLED_SUCCESS:
      return { ...state, scrollToPromoForm: false };
    case REQUEST_SWEEPS_CONSENT:
      return { ...state, loading: true };
    case SWEEPS_CONSENT_SUCCESS:
      return {
        ...state, loading: false, sweepsConsentSuccess: true,
        firstName: action.user.firstName, lastName: action.user.lastName,
        email: action.user.email
      };
    case SWEEPS_CONSENT_FAILURE:
      return { ...state, validated: false, error: action.error, loading: false };
    case RESET_PROMO_STATE:
      return state;
    default:
      return state;
  }
};