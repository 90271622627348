import {
  RENDER_CONFIRMATION
} from '../actionCreators/ActionTypes';

const INITIAL_STATE = {
  renderConfirmation: false
}

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case RENDER_CONFIRMATION: {
      return { ...state, renderConfirmation: true };
    }
    default:
      return state;
  }
};