import {
  HANDLE_RP_PASSWORD_INPUT,
  RP_PASSWORD_FORM_VALIDATED,
  SET_HASH_ID,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  newPassword: "",
  confirmPassword: "",
  hashId: "",
  pswdFormvalidated: false,
  apiErr: "",
  loading: false,
  isResetSuccess: false,
  passport: ""
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case HANDLE_RP_PASSWORD_INPUT:
      return {
        ...state,
        [action.key]: action.value,
        pswdFormvalidated: false,
        apiErr: ""
      };
    case RP_PASSWORD_FORM_VALIDATED: {
      return { ...state, pswdFormvalidated: action.flag, apiErr: "" };
    }
    case SET_HASH_ID:
      return { ...state, hashId: action.hashId };
    case REQUEST_RESET_PASSWORD:
      return { ...state, loading: true, apiErr: "" };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        apiErr: "",
        loading: false,
        pswdFormvalidated: true,
        isResetSuccess: true,
        passport: action.passport
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        pswdFormvalidated: false,
        apiErr: action.error,
        loading: false
      };
    default:
      return state;
  }
};
