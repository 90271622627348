import {
  REQUEST_PAYMENT_METHOD, PAYMENT_METHOD_SUCCESS, PAYMENT_METHOD_FAILURE
} from '../actionCreators/ActionTypes';

const INITIAL_STATE = {
  loading: false,
  paymentMethod: {
    cardType: '',
    lastFour: '',
    expirationYear: '',
    expirationMonth: '',
    billingAddress: {
      postalCode: '',
      firstName: '',
      lastName: ''
    }
  },
  paymentMethodErr: ''
}

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case REQUEST_PAYMENT_METHOD: {
      return { ...state, loading: true };
    }
    case PAYMENT_METHOD_SUCCESS: {
      return { ...state, loading: false, paymentMethod: action.data };
    }
    case PAYMENT_METHOD_FAILURE: {
      return { ...state, loading: false, paymentMethodErr: action.error };
    }
    default:
      return state;
  }
};