import {
  REQUEST_SOCIAL_SSO, SOCIAL_SSO_SUCCESSFUL, SOCIAL_SSO_ERROR
} from '../actionCreators/ActionTypes';

const INITIAL_STATE = {
  loading: false,
  socialSSOErr: ''
}

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case REQUEST_SOCIAL_SSO: {
      return { ...state, loading: true };
    }
    case SOCIAL_SSO_SUCCESSFUL: {
      return { ...state, loading: false };
    }
    case SOCIAL_SSO_ERROR: {
      return { ...state, loading: false, socialSSOErr: action.error };
    }
    default:
      return state;
  }
};