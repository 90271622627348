import {
  HANDLE_RP_EMAIL_INPUT, RP_EMAIL_FORM_VALIDATED, REQUEST_FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE, RESET_FP_STATE
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  email: '',
  emFormvalidated: false,
  apiErr: '',
  loading: false,
  isFPSuccess: false
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case HANDLE_RP_EMAIL_INPUT:
      return { ...state, [action.key]: action.value, emFormvalidated: false, apiErr: '' };
    case RP_EMAIL_FORM_VALIDATED:
      return { ...state, emFormvalidated: action.flag, apiErr: '' };
    case REQUEST_FORGOT_PASSWORD:
      return { ...state, loading: true, apiErr: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state, apiErr: '', loading: false, emFormvalidated: true, isFPSuccess: true
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state, emFormvalidated: false, apiErr: action.error, loading: false
      };
    case RESET_FP_STATE: {
      state = INITIAL_STATE;
      return state;
    }
    default:
      return state;
  }
};