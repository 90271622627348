import {
  SET_SESSION_FLAG, SET_PURCHASED_FLAG, SET_SESSION_USER, SET_SUBSCRIPTION_STATUS
} from "../actionCreators/ActionTypes";

export const INITIAL_STATE = {
  isSession: false,
  isPurchased: false,
  sessionUser: {
    id: '',
    token: '',
    identifier: '',
    displayName: '',
    flag: false,
    first_name: '',
    last_name: '',
    passport: ''
  },
  sessionType: 'email', // default to email, possible values facebook, google, email
  subscriptionStatus: {},
  sessionProperty: 'at_home' // default session property
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case SET_SESSION_FLAG: {
      localStorage.setItem('sessionProperty', action.sessionProperty);
      return { ...state, isSession: true, sessionProperty: action.sessionProperty };
    }
    case SET_PURCHASED_FLAG:
      return { ...state, isPurchased: true };
    case SET_SESSION_USER: {
      state.sessionType = action.sessionType;
      state.sessionUser.id = action.user.id;
      state.sessionUser.token = action.user.token;
      state.sessionUser.email = action.user.identifier;

      if (action.user.firstName) {
        state.sessionUser.first_name = action.user.firstName;
      }
      if (action.user.lastName) {
        state.sessionUser.last_name = action.user.lastName;
      }

      if (action.user.displayName) {
        state.sessionUser.displayName = action.user.displayName;
        if (state.sessionUser.displayName === '' || state.sessionUser.displayName === ' ') {
          state.sessionUser.displayName = 'Hello there!';
        }
      } else {
        if (action.user.firstName) {
          state.sessionUser.displayName = action.user.firstName;
        } else {
          state.sessionUser.displayName = 'Hello there!';
        }
      }

      if (action.user.flag) {
        state.sessionUser.flag = action.user.flag;
        if (action.state === 'purchased') {
          state.isPurchased = true;
        }
      }

      if (action.user.passport) {
        state.sessionUser.passport = action.user.passport;
      }

      if (!action.user.error) {
        localStorage.setItem('email', action.user.identifier);
        localStorage.setItem('sessionType', action.sessionType);
        localStorage.setItem('isSession', true);
        localStorage.setItem('userId', action.user.id);
      }

      return { ...state, isSession: true };
    }
    case SET_SUBSCRIPTION_STATUS:
      return { ...state, subscriptionStatus: action.data };
    default:
      return state;
  }
};