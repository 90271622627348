import React, { Suspense, lazy, Component } from 'react';
import Loadable from 'react-loadable';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import { connect } from 'react-redux';
import { getMetadata, getPartnerPlanId } from "./utils/utils";
import {
  setInitialAppProperties, fetchUUID, fetchPlan,
  fetchStylist, fetchUser, decodePassport
} from "./actionCreators/CommonActions";
import { resetStore } from './actionCreators/index';
import Loading from './components/Loading';
import { getGoogleRecaptchaSiteKey } from './utils/utils';

const Sweepstakes = Loadable({
  loader: () => import("./pages/Promos/EgglandsBest"),
  loading() {
    return <Loading />;
  },
});

const Registration = Loadable({
  loader: () => import("./pages/Register/Registration"),
  loading() {
    return <Loading />;
  },
});

const CoreRegistration = Loadable({
  loader: () => import("./pages/Register/CoreRegistration"),
  loading() {
    return <Loading />;
  },
});

const Payment = Loadable({
  loader: () => import("./pages/Payment/Payment"),
  loading() {
    return <Loading />;
  },
});

const LegacyPayment = Loadable({
  loader: () => import("./pages/LegacyPayment/LegacyPayment"),
  loading() {
    return <Loading />;
  },
});

const CancelSubs = Loadable({
  loader: () => import("./pages/CancelSubscription/CancelSubs"),
  loading() {
    return <Loading />;
  },
});

const ForgotPassword = Loadable({
  loader: () => import("./pages/ForgotPassword/ForgotPassword"),
  loading() {
    return <Loading />;
  },
});

const ResetPassword = Loadable({
  loader: () => import("./pages/ResetPassword/ResetPassword"),
  loading() {
    return <Loading />;
  },
});

const SamsClub = Loadable({
  loader: () => import("./pages/SamsClub/SamsClub"),
  loading() {
    return <Loading />;
  },
});

const RedeemGift = Loadable({
  loader: () => import("./pages/RedeemGift/RedeemGift"),
  loading() {
    return <Loading />;
  },
});

const Redeem = Loadable({
  loader: () => import("./pages/Redeem/Redeem"),
  loading() {
    return <Loading />;
  },
});

const AccountSummary = Loadable({
  loader: () => import("./components/Register/AccountSummary"),
  loading() {
    return <Loading />;
  },
});

const Signup = Loadable({
  loader: () => import("./pages/Signup/Signup"),
  loading() {
    return <Loading />;
  },
});

const EmealsLTO = Loadable({
  loader: () => import("./components/EmealsLTO"),
  loading() {
    return <Loading />;
  },
});

const MobileMenu = Loadable({
  loader: () => import("./pages/MobileMenu/MobileMenu"),
  loading() {
    return <Loading />;
  },
});

const Landing = Loadable({
  loader: () => import("./pages/Landing/Landing"),
  loading() {
    return <Loading />;
  },
});

const Signin = Loadable({
  loader: () => import("./pages/Signin/Signin"),
  loading() {
    return <Loading />;
  },
});

let oldFrontendSignIn = null;
let signInDataWasRecieved = false;
class App extends Component {

  componentDidMount() {
    let params = new URLSearchParams(document.location.search.substring(1));
    let partner;
    let creative;
    let campaign;
    let property = 'at_home';
    let code;
    let partnerPlanId = 664;
    let passport = '';
    let itemKey = '';

    if (params.get("p_id")) {
      partner = params.get("p_id");
    }

    if (params.get("c_id")) {
      creative = params.get("c_id");
    }

    if (params.get("c_source")) {
      campaign = params.get("c_source");
    }

    if (params.get("property")) {
      property = params.get("property");
    }

    if (params.get("code")) {
      property = params.get("code");
    }

    if (params.get("passport")) {
      passport = params.get("passport");
      this.props.resetStore();
    }

    if (params.get("item_key")) {
      itemKey = params.get("item_key");
    }

    partnerPlanId = getPartnerPlanId(params, property);

    const isSession = localStorage.getItem('isSession');

    getMetadata(property);
    this.props.setInitialAppProperties(property, partner, creative, code, itemKey, params.toString());

    this.props.fetchUUID();

    if (property !== 'default') {
      this.props.fetchPlan(partnerPlanId);
      this.props.fetchStylist();

      if (passport !== '') {
        this.props.decodePassport(passport);
      }

      if (isSession === 'true') {
        // const userId = localStorage.getItem('userId');
        const email = localStorage.getItem('email');
        const sessionType = localStorage.getItem('sessionType');
        this.props.fetchUser(email, sessionType);
      }
    }

    // Push all relevant data to dataLayer for GTM
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
      'property': property,
      'partner': partner,
      'creative': creative,
      'campaign': campaign
    });

    if (property === 'at_home') {
      fetch('https://dailyburn.com/app').then(function (response) {
        return response.url;
      }).then(function (url) {
        if (url === 'https://dailyburn.com/app') {
          oldFrontendSignIn = true;
        } else {
          oldFrontendSignIn = false;
        }
      }).catch(function (err) {
        oldFrontendSignIn = false;
        console.warn('Something went wrong.', err);
      });
    }
  }

  componentDidUpdate() {
    const { props } = this;
    if (signInDataWasRecieved === true) {
      return;
    }

    if (oldFrontendSignIn === null) {
      return;
    }

    if (oldFrontendSignIn === false) {
      signInDataWasRecieved = true;
      this.props.resetStore();
      return;
    }

    const isPurchased = props.home.isPurchased;
    const isSession = props.home.isSession;
    const sessionProperty = props.home.sessionProperty;
    const property = props.common.property;

    if (!isPurchased || !isSession) {
      return;
    }

    if (property !== 'at_home' || sessionProperty !== 'at_home') {
      return;
    }

    signInDataWasRecieved = true;
    const passport = localStorage.getItem('passport');
    const redirectUrl = `https://dailyburn.com/app?passport=${passport}`;

    window.location.assign(redirectUrl);
  }

  render() {
    const { apiCount } = this.props.common;
    if (apiCount > 0) {
      return (
        <Loading />
      );
    } else {
      return (
        <Router>
          <Switch>
            <Route path="/emeals"
              exact={true}
              render={(props) => <EmealsLTO {...props} />} />
            <Route path="/"
              exact={true}
              render={(props) => <Landing {...props} />} />
            <Route path="/signup"
              render={(props) => <Registration {...props} />} />
            <Route path="/core-registration"
              render={(props) => <CoreRegistration {...props} />} />
            <Route path="/register"
              render={(props) => <Signup {...props} />} />
            <Route path="/signin"
              render={(props) => 
                <GoogleReCaptchaProvider reCaptchaKey={getGoogleRecaptchaSiteKey()}>
                  <Signin {...props} />
                </GoogleReCaptchaProvider>
                } />
            <Route path="/account"
                   render={(props) => <MobileMenu {...props} />} />
            <Route path="/sweepstakes"
                   render={(props) => <Sweepstakes {...props} />} />
            <Route path="/profile"
              render={(props) => <AccountSummary {...props} />} />
            <Route path="/payment"
              render={(props) => <Payment {...props} />} />
            <Route path="/lpayment"
              render={(props) => <LegacyPayment {...props} />} />
            <Route path="/cancel"
              render={(props) => <CancelSubs {...props} />} />
            <Route path="/sams-club"
              render={(props) => <SamsClub {...props} />}/>
            <Route path="/forgotpassword"
              render={(props) => <ForgotPassword {...props} />} />
            <Route path="/resetpassword"
              render={(props) => <ResetPassword {...props} />} />
            <Route path="/resetpassword"
                render={(props) => <ResetPassword {...props} />} />
            <Route path="/redeem"
                render={(props) => <Redeem {...props} />}/>
            <Route path="/redeem-gift/:coupon"
                render={(props) => <RedeemGift {...props} />} />
          {/* https://portal.dailyburn.com/redeem-gift/E2KB7SX9DMJFR6YP */}
          </Switch>
        </Router>
      );
    }
  }
}

const mapStateToProps = state => ({
  common: state.common,
  home: state.home,
});

const mapDispatchToProps = {
  setInitialAppProperties,
  fetchUUID,
  fetchPlan,
  fetchStylist,
  fetchUser,
  resetStore,
  decodePassport
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
