import {
  UPDATE_SCROLL_EVENT_FLAG, SHOW_STICKY_CTA, HIDE_STICKY_CTA
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  is25PerScrollEventSent: false,
  is50PerScrollEventSent: false,
  is75PerScrollEventSent: false,
  is90PerScrollEventSent: false,
  is100PerScrollEventSent: false,
  showStickyCTA: false
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case UPDATE_SCROLL_EVENT_FLAG:
      return { ...state, [action.flagName]: true };
    case SHOW_STICKY_CTA:
      return { ...state, showStickyCTA: true };
    case HIDE_STICKY_CTA:
      return { ...state, showStickyCTA: false };
    default:
      return state;
  }
};