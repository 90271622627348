import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from '../reducers';

const logger = createLogger({
  collapsed: true,
});

export default function configureStore(initialState) {

  const getMiddleware = () => {
    if (process.env.NODE_ENV === 'development') {
      return applyMiddleware(ReduxThunk, logger);
    }
    return applyMiddleware(ReduxThunk);
  };

  const store = createStore(
    rootReducer,
    initialState,
    getMiddleware(),
  );

  return store;
}