import {
  SET_INITIAL_APP_PROPERTIES, REQUEST_UUID, UUID_SUCCESS,
  UUID_FAILURE, REQUEST_PLAN, PLAN_SUCCESS, PLAN_FAILURE,
  REQUEST_STYLIST, STYLIST_SUCCESS, STYLIST_FAILURE,
  REQUEST_FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_FAILURE,
  REQUEST_SUBSCRIPTION_STATUS, SUBSCRIPTION_STATUS_SUCCESS, SUBSCRIPTION_STATUS_FAILURE,
  REQUEST_DECODE_PASSPORT, DECODE_PASSPORT_FAILURE, USER_BY_TOKEN_FAILURE, USER_BY_TOKEN_SUCCESS,
  UPDATE_PROMO_CODE
} from '../actionCreators/ActionTypes';

const INITIAL_STATE = {
  apiCount: 0, // used to manage api calls count in main app.js file to display/hide loading
  property: 'yoga',
  partner: '',
  creative: '',
  promoCode: '',
  loading: false, // we're not using this loading field anymore in app.js file
  searchParams: '',
  plan: {
    partner: '',
    name: 'Monthly Plan',
    price: '999',
    periodicity: 'month',
    trial_duration: '30'
  },
  yogaStylistRes: {
    hero: {
      backgroundAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/yoga/LP_Yoga_v4.mp4",
      appIconAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/yoga/yoga-app-icon.png",
      headline: "Yoga Workouts",
      subHeadline: "Yoga Inspired Fitness for Every Level. Reach Your Fitness Goals with Yoga Workouts Personalized For You.",
      disclaimer: "Available only on iOS devices.",
      starsAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/yoga/yoga-stars-rating.png"
    },
    cta: {
      trialLength: "30 days",
      planPrice: "9.99",
      planPeriodicity: "Monthly",
      ctaCopy: "JOIN NOW"
    },
    featureBanner: {
      backgroundAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/yoga/featured-wave-desktop.png",
      backgroundAssetMobileUrl: "https://assets1.dailyburn.com/production/d2c/landing/yoga/featured-wave-mobile.png",
      copy: null
    },
    components: [
      {
        sortOrder: 0,
        sectionName: "PersonalizedRoutines",
        headline: "New Personalized Routines Every Week",
        subHeadline: "Engage your body and mind or build lean muscle as you flow through every bend, bridge, plank, and pose.",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/yoga/PersonalizedRoutines/sectionAsset.png",
        contentSide: "right",
        subAssetUrl: null,
        subAssetType: null,
        subAssetSize: null,
        subAssetLink: null
      },
      {
        sortOrder: 1,
        sectionName: "GuidedClasses",
        headline: "1-on-1 Guided Video Classes",
        subHeadline: "Your personal yoga instructor will carefully guide you through each down dog, chair pose, and leg lift. Feel your core get stronger and your muscles get leaner as you grow in your practice.",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/yoga/GuidedClasses/sectionAsset.png",
        contentSide: "left",
        subAssetUrl: null,
        subAssetType: null,
        subAssetSize: null,
        subAssetLink: null
      },
      {
        sortOrder: 2,
        sectionName: "AnywhereAnytime",
        headline: "Follow and Flow Anywhere, Anytime",
        subHeadline: "Follow each yoga video workout right from your phone. Choose from 15-45 minute routines, displayed in full-screen video for an all-encompassing yoga experience wherever you may be.",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/yoga/AnywhereAnytime/sectionAsset.png",
        contentSide: "right",
        subAssetUrl: null,
        subAssetType: null,
        subAssetSize: null,
        subAssetLink: null
      },
      {
        sortOrder: 3,
        sectionName: "UnlimitedWorkouts",
        headline: "Unlock Unlimited Workouts & a Variety of Yoga Challenges",
        subHeadline: "Search categories like weight loss, hip openers, and balance or select 3-5 minutes stretches for an instant pick me up.",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/yoga/UnlimitedWorkouts/sectionAsset.png",
        contentSide: "left",
        subAssetUrl: null,
        subAssetType: null,
        subAssetSize: null,
        subAssetLink: null
      },
      {
        sortOrder: 4,
        sectionName: "Community",
        headline: "Namaste with a Community of Yoga Lovers",
        subHeadline: "Connect with others just like you who have embarked on their own yoga journey. Get motivation from their inspiring stories, and share your own thoughts and comments. You can even post photos of your personal achievements. See you on the mat!",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/yoga/Community/sectionAsset.png",
        contentSide: "right",
        subAssetUrl: null,
        subAssetType: null,
        subAssetSize: null,
        subAssetLink: null
      }
    ]
  },
  runningStylistRes: {
    hero: {
      backgroundAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/LP_Running_v1.mp4",
      appIconAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/running-app-icon.png",
      headline: "Running",
      subHeadline: "Find Your Inner Runner. Reach Your Fitness Goals with a Running App Personalized For You - For Every Fitness Level.",
      disclaimer: "Available only on iOS devices.",
      starsAssetUrl: null
    },
    cta: {
      trialLength: "30 days",
      planPrice: "9.99",
      planPeriodicity: "Monthly",
      ctaCopy: "START YOUR 30-DAY FREE TRIAL"
    },
    featureBanner: {
      backgroundAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/featured-wave-desktop.png",
      backgroundAssetMobileUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/featured-wave-mobile.png",
      copy: "Join the millions of motivated Daily Burn users working out anytime, anywhere"
    },
    components: [
      {
        sortOrder: 0,
        sectionName: "TailoredForYou",
        headline: "Running Plans Tailored for You & Your Fitness Goals",
        subHeadline: "Running to lose weight, training for a 5k, tracking distance or running as a fun workout? Let us design a weekly training plan around your personal goals. Whether you've got 15 or 45 minutes to spare, just pop in your earbuds and go!",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/TailoredForYou/sectionAsset.png",
        contentSide: "right",
        subAssetUrl: null,
        subAssetType: null,
        subAssetSize: null,
        subAssetLink: null
      },
      {
        sortOrder: 1,
        sectionName: "UnlimitedPlans",
        headline: "Unlimited Training Plans & A Variety of Runs",
        subHeadline: "New runs, challenges & training plans every week. Choose from 15 minute to 45 minute runs including intervals, tempo and long runs.",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/UnlimitedPlans/sectionAsset.png",
        contentSide: "left",
        subAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/UnlimitedPlans/sectionSubAsset.png",
        subAssetType: "img",
        subAssetSize: "rectangle",
        subAssetLink: null
      },
      {
        sortOrder: 2,
        sectionName: "PersonalCoaching",
        headline: "1-on-1 Personal Coaching",
        subHeadline: "Your personal running app coach will be in your ear, guiding you through every jog, sprint, and straightaway. With this audio inspiration, you’ll know when to take it slow or pick up the pace.",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/PersonalCoaching/sectionAsset.png",
        contentSide: "right",
        subAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/PersonalCoaching/sectionSubAsset.png",
        subAssetType: "img",
        subAssetSize: "square",
        subAssetLink: null
      },
      {
        sortOrder: 3,
        sectionName: "AnywhereAnytime",
        headline: "Run Anywhere, Anytime",
        subHeadline: "Choose from guided treadmill runs or outdoor runs right from your phone.",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/AnywhereAnytime/sectionAsset.png",
        contentSide: "left",
        subAssetUrl: null,
        subAssetType: null,
        subAssetSize: null,
        subAssetLink: null
      },
      {
        sortOrder: 4,
        sectionName: "ManageYourRun",
        headline: "Manage & Track Your Run",
        subHeadline: "Track your milage, pace and calories. Stream your own playlist from Spotify and Apple Music OR let our curated playlists push you to the max. Each and every run belongs to you.",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/ManageYourRun/sectionAsset.png",
        contentSide: "right",
        subAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/ManageYourRun/sectionSubAsset.png",
        subAssetType: "img",
        subAssetSize: "rectangle",
        subAssetLink: null
      },
      {
        sortOrder: 5,
        sectionName: "WarmUp",
        headline: "Warm Up & Recover Like a Pro",
        subHeadline: "Follow our video guided warm ups and stretches specifically designed for runners. They target specific muscle groups to reduce muscle tightness keeping you mobile and ready for your next run.",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/WarmUp/sectionAsset.png",
        contentSide: "left",
        subAssetUrl: null,
        subAssetType: null,
        subAssetSize: null,
        subAssetLink: null
      },
      {
        sortOrder: 6,
        sectionName: "Connect",
        headline: "Connect with Your Tribe",
        subHeadline: "We wouldn’t be Daily Burn without a supportive group of runners to share your challenges and milestones. So go on—share, chat, and commiserate. But make sure to post photos as you mark your personal best!",
        assetType: "img",
        assetUrl: "https://assets1.dailyburn.com/production/d2c/landing/running/Connect/sectionAsset.png",
        contentSide: "right",
        subAssetUrl: null,
        subAssetType: null,
        subAssetSize: null,
        subAssetLink: null
      }
    ]
  },
  atHomeStylistRes: {
    hero: {
      backgroundAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/at-home/LP_Core_v6.mp4",
      appIconAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/at-home/at-home-app-icon.png",
      headline: "At Home Workouts",
      subHeadline: "One membership, thousands of different workout videos so you can mix it up every day right from your own home.",
      disclaimer: null,
      starsAssetUrl: null
    },
    cta: {
      trialLength: "30 days",
      planPrice: "19.95",
      planPeriodicity: "Monthly",
      ctaCopy: "START YOUR 30-DAY FREE TRIAL"
    },
    components: [
      {
        sortOrder: 0,
        reactiveComponent: "Landing365",
      },
      {
        sortOrder: 1,
        reactiveComponent: "LandingPrograms",
      },
      {
        sortOrder: 2,
        reactiveComponent: "LandingPeople",
      },
      {
        sortOrder: 3,
        reactiveComponent: "LandingTools",
      },
    ],
    featureBanner: false,
    legacyFooter: true,
    clean: true,
  },
  hAStylistRes: {
    hero: {
      heroImg: '',
      heroPartnerImg: '',
      strongHeadline: 'GET FIT AND WIN',
      headline: 'THE ULTIMATE TRIP',
      backgroundImg: '',
      copyColor: '#2b376f',
    },
    cta: {
      copy: '',
      copyColor: '',
      buttonColor: '',
    },
    instructions: {
      header: {
        copy: '',
        color: '',
      },
      enterComponents: [
        {
          sortOrder: 0,
          sectionName: "Step One",
          headline: "Fill out the form",
          subHeadline: "After that, you’re all set! Keep following along for bonus entries.",
          assetType: "img",
          assetUrl: "https://assets1.dailyburn.com/production/d2c/promos/holland-america/instructions/fill-out-form.png",
          contentSide: null,
          subAssetUrl: null,
          subAssetType: null,
          subAssetSize: null,
          subAssetLink: null
        },
        {
          sortOrder: 1,
          sectionName: "Step Two",
          headline: "Join Daily Burn",
          subHeadline: "Available on iOS, Android, AppleTV, Roku, Google Chromecase, Amazon Fire, Desktop and Mobile web. A download may be necessary.",
          assetType: "img",
          assetUrl: "https://assets1.dailyburn.com/production/d2c/promos/holland-america/instructions/download-db-app.png",
          contentSide: null,
          subAssetUrl: null,
          subAssetType: null,
          subAssetSize: null,
          subAssetLink: null
        }
      ],
      enteriesComponents: [
        {
          sortOrder: 2,
          sectionName: "Step Three",
          headline: "Watch videos",
          subHeadline: "Work out with Daily Burn by watching videos with your favorite trainers, up to 3 unique videos per day.",
          assetType: "img",
          assetUrl: "https://assets1.dailyburn.com/production/d2c/promos/holland-america/instructions/watch-videos.png",
          contentSide: null,
          subAssetUrl: null,
          subAssetType: null,
          subAssetSize: null,
          subAssetLink: null
        },
        {
          sortOrder: 3,
          sectionName: "Step Four",
          headline: "Follow @dailyburn",
          subHeadline: "on Instagram and Twitter for an extra entry each. Make sure to include your handles when you register for your entries to count!",
          assetType: "img",
          assetUrl: "https://assets1.dailyburn.com/production/d2c/promos/holland-america/instructions/follow-on-social.png",
          contentSide: null,
          subAssetUrl: null,
          subAssetType: null,
          subAssetSize: null,
          subAssetLink: null
        },
        {
          sortOrder: 4,
          sectionName: "Step Five",
          headline: "Share a photo",
          subHeadline: "Share a photo of you exercising with Daily Burn or a photo of yourself on a past Holland America cruise with the hashtag #DailyBurnCruiseSweepstakes for extra entries on Twitter and Instagram, up to one per platform per day.",
          assetType: "img",
          assetUrl: "https://assets1.dailyburn.com/production/d2c/promos/holland-america/instructions/post-on-social.png",
          contentSide: null,
          subAssetUrl: null,
          subAssetType: null,
          subAssetSize: null,
          subAssetLink: null
        }
      ]
    },
    subFeatureBanner: {
      headline: 'Two lucky winners and their guests will enjoy a',
      strongHeadline: '7-DAY CRUISE TO ALASKA!',
      copyColor: '#2b376f',
      imgUrl: ''
    },
    subHero: {
      backgroundImg: '',
      stickerImg: '',
      headline: 'Start your fitness journey and',
      strongHeadline: 'GET REWARDED',
      subHeadline: 'Daily Burn and Holland America Line have teamed up to help you meet your health and wellness goals by rewarding you when you work out! We’ll be sending two lucky winners on a 7-day Alaskan cruise for themselves and a guest. We’ll also be hooking you up with a year of Daily Burn At Home, Running, Yoga, and HIIT, and some fitness accessories to keep you on track.',
      disclaimer: 'NO PURCHASE NECESSARY. Enter sweepstakes by 3/27/20 at 11:59 p.m. ET. Open to legal residents of the 50 U.S. & D.C., 18+. Prize winners must travel before November 15, 2020. Transportation to and from port city not included in Prize. See Official Rules [insert link to Official Rules] for full details including eligibility, bonus entries, odds, prize descriptions & limitations. Void where prohibited.'
    },
    footer: {
      bgColor: '#2b376f',
      textColor: '#ffffff',
      logoImg: 'https://assets1.dailyburn.com/production/d2c/promos/holland-america/footer/db-footer-logo.png',
      partnerLogoImg: ''
    }
  },
  stylistRes: {
    hero: {
      backgroundAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/at-home/LP_Core_v6.mp4",
      appIconAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/at-home/at-home-app-icon.png",
      headline: "At Home Workouts",
      subHeadline: "One membership, thousands of different workout videos so you can mix it up every day right from your own home.",
      disclaimer: null,
      starsAssetUrl: null
    },
    cta: {
      trialLength: "30 days",
      planPrice: "19.95",
      planPeriodicity: "Monthly",
      ctaCopy: "START YOUR 30-DAY FREE TRIAL"
    },
    components: [
      {
        sortOrder: 0,
        reactiveComponent: "Landing365",
      },
      {
        sortOrder: 1,
        reactiveComponent: "LandingPrograms",
      },
      {
        sortOrder: 2,
        reactiveComponent: "LandingPeople",
      },
      {
        sortOrder: 3,
        reactiveComponent: "LandingTools",
      },
    ],
    featureBanner: false,
    legacyFooter: true,
    clean: true,
  },
}

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case SET_INITIAL_APP_PROPERTIES: {
      return {
        ...state,
        property: action.property,
        partner: action.partner,
        creative: action.creative,
        itemKey: action.itemKey,
        promoCode: action.code,
        searchParams: action.searchParams
      };
    }
    case REQUEST_UUID: {
      state.apiCount = incrementAPICount(state.apiCount);
      return { ...state };
    }
    case UUID_SUCCESS: {
      state.apiCount = decrementAPICount(state.apiCount);
      localStorage.setItem('adjustUUID', action.data);
      return { ...state };
    }
    case UUID_FAILURE: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state };
    }
    case REQUEST_PLAN: {
      state.apiCount = incrementAPICount(state.apiCount);
      return { ...state };
    }
    case PLAN_SUCCESS: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state, plan: action.data };
    }
    case PLAN_FAILURE: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state };
    }
    case REQUEST_STYLIST: {
      state.apiCount = incrementAPICount(state.apiCount);
      return { ...state };
    }
    case STYLIST_SUCCESS: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state, stylistRes: action.data };

    }
    case STYLIST_FAILURE: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state };

    }
    case REQUEST_FETCH_USER: {
      state.apiCount = incrementAPICount(state.apiCount);
      return { ...state };
    }
    case FETCH_USER_SUCCESS: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state };
    }
    case FETCH_USER_FAILURE: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state };
    }
    case REQUEST_SUBSCRIPTION_STATUS: {
      state.apiCount = incrementAPICount(state.apiCount);
      return { ...state };
    }
    case SUBSCRIPTION_STATUS_SUCCESS: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state };
    }
    case SUBSCRIPTION_STATUS_FAILURE: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state };
    }
    case REQUEST_DECODE_PASSPORT: {
      state.apiCount = incrementAPICount(state.apiCount);
      return { ...state };
    }
    case DECODE_PASSPORT_FAILURE: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state };
    }
    case USER_BY_TOKEN_SUCCESS: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state };
    }
    case USER_BY_TOKEN_FAILURE: {
      state.apiCount = decrementAPICount(state.apiCount);
      return { ...state };
    }
    case UPDATE_PROMO_CODE: {
      return { ...state, promoCode: action.promoCode };
    }
    default:
      return state;
  }
};

function incrementAPICount(count) {
  return count += 1;
}

function decrementAPICount(count) {
  return count -= 1;
}