import React from 'react';

import './Loading.css';

const Loading = () => {
  return (
    <div className="loading-container">
      <img
        src="https://assets1.dailyburn.com/production/d2c/logos/spinner.gif"
        alt="loading-spinner"
        height="180px"
        width="180px"
      />
    </div>
  );
};

export default Loading;