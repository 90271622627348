import {
  BT_HOSTEDFIELD_INSTANCE,
  BT_HOSTEDFIELD_INSTANCE_READY,
  PAYMENT_METHOD_NONCE_RECEIVED,
  REQUEST_BRAINTREE_CLIENT_TOKEN,
  BRAINTREE_CLIENT_TOKEN_RECEIVED,
  BRAINTREE_CLIENT_TOKEN_FAILED,
  REQUEST_JOIN_PLAN,
  JOIN_PLAN_RECEIVED,
  JOIN_PLAN_FAILED,
  CLEAR_PAYMENT_METHOD,
  SET_PATH_NAME,
  REQUEST_PAYMENT_METHOD,
  PAYMENT_METHOD_SUCCESS,
  PAYMENT_METHOD_FAILURE
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  clientToken: "",
  paymentUpdated: false,
  loading: false,
  paymentMethodNonce: "",
  btClientTokenErr: "",
  joinPlanErr: "",
  isJoinPlanError: false,
  dropinInstance: null,
  purchased: false,
  pathName: "",
  confirmationCopy: {
    iconUrl:
      "https://assets1.dailyburn.com/production/d2c/registration/confirmation/congratulations-icon.png",
    headline: "Congratulations!",
    subHeadline:
      "You just signed up for a free 30-day trial of Yoga Workouts. After the trial is over, you’ll be charged $9.99 a month."
  },
  btHostedIns: null,
  btHostedfieldInstance: null,
  btTokenLoading: false,
  paymentMethod: {
    cardType: '',
    lastFour: '',
    expirationYear: '',
    expirationMonth: '',
    billingAddress: {
      postalCode: '',
      firstName: '',
      lastName: ''
    }
  },
  paymentMethodErr: ""
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case PAYMENT_METHOD_NONCE_RECEIVED:
      return {
        ...state,
        isJoinPlanError: false,
        paymentUpdated: true,
        paymentMethodNonce: action.nonce,
        dropinInstance: action.dropinInstance
      };
    case BT_HOSTEDFIELD_INSTANCE_READY:
      return {
        ...state,
        btHostedIns: action.btHostedIns
      };
    case BT_HOSTEDFIELD_INSTANCE:
      return {
        ...state,
        btHostedfieldInstance: action.btHostedfieldInstance
      };
    case REQUEST_BRAINTREE_CLIENT_TOKEN:
      return { ...state, loading: false, btTokenLoading: true };
    case BRAINTREE_CLIENT_TOKEN_RECEIVED:
      return { ...state, loading: false, clientToken: action.token };
    case BRAINTREE_CLIENT_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
        clientToken: "",
        btClientTokenErr: action.btClientTokenErr
      };
    case REQUEST_JOIN_PLAN:
      return { ...state, loading: true };
    case JOIN_PLAN_RECEIVED:
      return { ...state, loading: false };
    case JOIN_PLAN_FAILED: {
      if (state.dropinInstance) {
        state.dropinInstance.clearSelectedPaymentMethod();
      } else if (state.btHostedfieldInstance) {
        if (typeof state.btHostedfieldInstance.clear === "function") {
          state.btHostedfieldInstance.clear("number");
          state.btHostedfieldInstance.clear("cvv");
          state.btHostedfieldInstance.clear("expirationMonth");
          state.btHostedfieldInstance.clear("expirationYear");
          state.btHostedfieldInstance.clear("postalCode");
        }
      }
      return {
        ...state,
        loading: false,
        clientToken: "",
        isJoinPlanError: true,
        joinPlanErr: action.joinPlanErr,
        paymentUpdated: false,
        btTokenLoading: false,
        btHostedfieldInstance: null
      };
    }
    case CLEAR_PAYMENT_METHOD: {
      if (state.dropinInstance) {
        state.dropinInstance.clearSelectedPaymentMethod();
      } else if (state.btHostedfieldInstance) {
        if (typeof state.btHostedfieldInstance.clear === "function") {
          state.btHostedfieldInstance.clear("number");
          state.btHostedfieldInstance.clear("cvv");
          state.btHostedfieldInstance.clear("expirationMonth");
          state.btHostedfieldInstance.clear("expirationYear");
          state.btHostedfieldInstance.clear("postalCode");
        }
      }
      return {
        ...state,
        loading: false,
        paymentUpdated: false,
        isJoinPlanError: false,
        joinPlanErr: null,
        btTokenLoading: false,
        btHostedfieldInstance: null
      };
    }
    case SET_PATH_NAME:
      return { ...state, pathName: action.pathName };
    case REQUEST_PAYMENT_METHOD: {
      return { ...state, loading: true };
    }
    case PAYMENT_METHOD_SUCCESS: {
      return { ...state, loading: false, paymentMethod: action.data };
    }
    case PAYMENT_METHOD_FAILURE: {
      return { ...state, loading: false, paymentMethodErr: action.error };
    }
    default:
      return state;
  }
};
