import { combineReducers } from 'redux';
import CommonReducer from "./CommonReducer";
import HomeReducer, { INITIAL_STATE } from './HomeReducer';
import LandingReducer from './LandingReducer';
import RegistrationReducer from './RegistrationReducer';
import RegistrationFormReducer from "./RegistrationFormReducer";
import PaymentFormReducer from "./PaymentFormReducer";
import LandingCTAReducer from "./LandingCTAReducer";
import SigninReducer from "./SigninReducer";
import PaymentReducer from "./PaymentReducer";
import RegistrationSelectorReducer from "./RegistrationSelectorReducer";
import AccountSummaryReducer from "./AccountSummaryReducer";
import CancelSubsReducer from "./CancelSubsReducer";
import PromoRegFormReducer from "./PromoRegFormReducer";
import ForgotPasswordReducer from "./ForgotPasswordReducer";
import ResetPasswordReducer from './ResetPasswordReducer';
import CodeRegistrationReducer from './CodeRegistrationReducer';
import CodeRedemptionReducer from './CodeRedemptionReducer';
import RedeemReducer from './RedeemReducer';
import SamsClubReducer from './SamsClubReducer';

import {
  RESET_STORE
} from '../actionCreators/ActionTypes';

const appReducer = combineReducers({
  common: CommonReducer,
  home: HomeReducer,
  landing: LandingReducer,
  registration: RegistrationReducer,
  registrationForm: RegistrationFormReducer,
  paymentForm: PaymentFormReducer,
  landingCTA: LandingCTAReducer,
  signin: SigninReducer,
  payment: PaymentReducer,
  registrationSelector: RegistrationSelectorReducer,
  accountSummary: AccountSummaryReducer,
  cancelSubs: CancelSubsReducer,
  promoRegForm: PromoRegFormReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  redeem: RedeemReducer,
  codeRegistration: CodeRegistrationReducer,
  codeRedemption: CodeRedemptionReducer,
  samsClub: SamsClubReducer
});

export const rootReducer = (state, action) => {
  switch (action.type) {
    case RESET_STORE: {
      const { common } = state;
      state = { 
        common,
        home: {
          sessionUser: {
            id: '',
            token: '',
            identifier: '',
            displayName: '',
            flag: false,
            first_name: '',
            last_name: '',
            passport: ''
          }
        }
      };
      localStorage.removeItem('sessionType');
      localStorage.setItem('isSession', false);
      localStorage.removeItem('userId');
      localStorage.removeItem('sessionProperty');
      localStorage.removeItem('email');
      localStorage.removeItem('passport');

      return appReducer(state, action);
    }
    default: {
      return appReducer(state, action);
    }
  }
};