import {
  SET_INITIAL_APP_PROPERTIES, REQUEST_UUID, UUID_SUCCESS,
  UUID_FAILURE, REQUEST_PLAN, PLAN_SUCCESS, PLAN_FAILURE,
  REQUEST_STYLIST, STYLIST_SUCCESS, STYLIST_FAILURE,
  REQUEST_FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_FAILURE,
  REQUEST_SUBSCRIPTION_STATUS, SUBSCRIPTION_STATUS_SUCCESS, SUBSCRIPTION_STATUS_FAILURE,
  REQUEST_DECODE_PASSPORT, DECODE_PASSPORT_FAILURE, USER_BY_TOKEN_FAILURE, USER_BY_TOKEN_SUCCESS,
  UPDATE_PROMO_CODE
} from "./ActionTypes";
import {
  setSessionFlag, setPurchasedFlag,
  setSessionUser, setSubscriptionStatus
} from "./HomeActions";
import { getApiURL } from "../utils/utils";

export const setInitialAppProperties = (property, partner, creative, code, itemKey, searchParams) => ({
  type: SET_INITIAL_APP_PROPERTIES,
  property,
  partner,
  creative,
  code,
  itemKey,
  searchParams
});

export const updatePromoCode = (promoCode) => ({
  type: UPDATE_PROMO_CODE,
  promoCode
});

const requestUUID = () => ({
  type: REQUEST_UUID,
});

const uuidSuccess = response => ({
  type: UUID_SUCCESS,
  data: response
});

const uuidFailure = error => ({
  type: UUID_FAILURE,
  error
});

const requestPlan = (partnerPlanId) => ({
  type: REQUEST_PLAN,
  partnerPlanId,
});

const planSuccess = response => ({
  type: PLAN_SUCCESS,
  data: response
});

const planFailure = error => ({
  type: PLAN_FAILURE,
  error
});

const requestStylist = () => ({
  type: REQUEST_STYLIST,
});

const stylistSuccess = response => ({
  type: STYLIST_SUCCESS,
  data: response
});

const stylistFailure = error => ({
  type: STYLIST_FAILURE,
  error
});

const requestFetchUser = () => ({
  type: REQUEST_FETCH_USER,
});

const fetchUserSuccess = () => ({
  type: FETCH_USER_SUCCESS
});

const fetchUserFailure = error => ({
  type: FETCH_USER_FAILURE,
  error
});

const requestSubscriptionStatus = () => ({
  type: REQUEST_SUBSCRIPTION_STATUS,
});

const subscriptionStatusSuccess = () => ({
  type: SUBSCRIPTION_STATUS_SUCCESS
});

const subscriptionStatusFailure = error => ({
  type: SUBSCRIPTION_STATUS_FAILURE,
  error
});

const requestDecodePassport = () => ({
  type: REQUEST_DECODE_PASSPORT,
});

const decodePassportFailure = error => ({
  type: DECODE_PASSPORT_FAILURE,
  error
});

const userByTokenSuccess = () => ({
  type: USER_BY_TOKEN_SUCCESS
});

const userByTokenFailure = error => ({
  type: USER_BY_TOKEN_FAILURE,
  error
});

export const fetchUUID = () => {
  return (dispatch) => {
    const adjustUUID = localStorage.getItem('adjustUUID');
    if (!adjustUUID) {
      dispatch(uuidSuccess(adjustUUID));
    } else {
      dispatch(requestUUID());
      fetch(getApiURL() + "commons/uuid?type=user", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uuid: adjustUUID
        })
      }).then((response) => response.json())
        .then((json) => {
          dispatch(uuidSuccess(json.uuid))
        })
        .catch((error) => dispatch(uuidFailure(error)));
    }
  };
};

export const fetchPlan = (partnerPlanId) => {
  return (dispatch, getState) => {
    dispatch(requestPlan(partnerPlanId));
    fetch(getApiURL() + "plan/" + partnerPlanId + "?property=" + getState().common.property, {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => response.json())
      .then((json) => {
        dispatch(planSuccess(json.plans.data))
      })
      .catch((error) => dispatch(planFailure(error)));
  };
};

export const fetchStylist = () => {
  return (dispatch, getState) => {
    dispatch(requestStylist());
    console.log(getState().common.property)
    if (getState().common.property === 'at_home') {
      dispatch(stylistSuccess({
        hero: {
          backgroundAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/at-home/LP_Core_v6.mp4",
          appIconAssetUrl: "https://assets1.dailyburn.com/production/d2c/landing/at-home/at-home-app-icon.png",
          headline: "At Home Workouts",
          subHeadline: "One membership, thousands of different workout videos so you can mix it up every day right from your own home.",
          disclaimer: null,
          starsAssetUrl: null
        },
        cta: {
          trialLength: "30 days",
          planPrice: "19.95",
          planPeriodicity: "Monthly",
          ctaCopy: "START YOUR 30-DAY FREE TRIAL"
        },
        components: [
          {
            sortOrder: 0,
            reactiveComponent: "Landing365",
          },
          {
            sortOrder: 1,
            reactiveComponent: "LandingPrograms",
          },
          {
            sortOrder: 2,
            reactiveComponent: "LandingPeople",
          },
          {
            sortOrder: 3,
            reactiveComponent: "LandingTools",
          },
        ],
        featureBanner: false,
        legacyFooter: true,
        clean: true,
      }));
    } else {
      fetch(getApiURL() + "stylist/landing-page?property=" + getState().common.property + "&hl=en", {
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }).then((response) => response.json())
        .then((json) => {
          dispatch(stylistSuccess(json.style))
        })
        .catch((error) => dispatch(stylistFailure(error)));
    }
  };
};

export const fetchUser = (email, sessionType) => {
  return (dispatch, getState) => {
    const { property } = getState().common;
    dispatch(requestFetchUser());
    fetch(getApiURL() + `user?property=${property}&filters={"email":"${email}"}
    &attributes=id,token,identifier,displayName,firstName,lastName,flag,passport`, {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => response.json())
      .then((json) => {
        if (json.user.length > 0) {
          dispatch(setSessionFlag(property));
          dispatch(setSessionUser(json.user[0], sessionType));

          if (json.user[0].flag === true) {
            dispatch(setPurchasedFlag());
          }

          dispatch(fetchSubscriptionStatus());
        }
        dispatch(fetchUserSuccess());
        // else {
        //   const isSession = localStorage.getItem("isSession");
        //   const sessionProperty = localStorage.getItem("sessionProperty");
        //   if (isSession === 'true' && sessionProperty !== 'at_home') {
        //     dispatch(setSessionFlag(sessionProperty));
        //   }
        //   dispatch(fetchUserFailure(json.user[0].error.message));
        // }
      })
      .catch((error) => dispatch(fetchUserFailure(error)));
  };
};

export const fetchSubscriptionStatus = () => {
  return (dispatch, getState) => {
    dispatch(requestSubscriptionStatus());
    fetch(getApiURL() + "subscription-status/" + getState().home.sessionUser.id + "?property=" + getState().common.property, {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => response.json())
      .then((json) => {
        const state = json.response.data.state;
        if (state === 'ACTIVE' || state === 'PENDING_CANCELLATION') {
          dispatch(setPurchasedFlag());
        }
        dispatch(setSubscriptionStatus(json.response.data));
        dispatch(subscriptionStatusSuccess());
      })
      .catch((error) => dispatch(subscriptionStatusFailure(error)));
  };
};

// decode passport and get user by token are linked APIs
export const decodePassport = (pp) => {
  return (dispatch, getState) => {
    dispatch(requestDecodePassport());
    const { property } = getState().common;
    const payload = {
      passport: pp
    }
    fetch(getApiURL() + `passport/decode?property=${property}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(response => response.json())
      .then((json) => {
        // console.log("passport decoded... ", json);
        dispatch(getUserByTokenOrId(json.result));
      })
      .catch((error) => dispatch(decodePassportFailure("Failed to decode passport")));
  };
};

// fetch core/at_home user details by token and yoga/running user details by userId
export const getUserByTokenOrId = (identifier) => {
  return (dispatch, getState) => {
    // decode passport and get user by token are linked APIs so no need to dispatch requestAction here
    const { property } = getState().common;
    let url = '';
    if (property === 'at_home') {
      url = `user?property=${property}&filters={"token":"${identifier}"}`;
    } else {
      url = `user/${identifier}?property=${property}`
    }
    fetch(getApiURL() + url, {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => response.json())
      .then((json) => {
        if (json.user) {
          dispatch(userByTokenSuccess());
          dispatch(setSessionFlag(property));
          // auto login user flow would work with sessionType = email
          let user = {};
          if (json.user instanceof Array) {
            user = json.user[0];
          } else {
            user = json.user;
          }
          dispatch(setSessionUser(user, 'email'));
          if (user.flag === true) {
            dispatch(setPurchasedFlag());
          }
          dispatch(fetchSubscriptionStatus());
        } else {
          dispatch(userByTokenFailure('something went wrong'));
        }
      })
      .catch((error) => dispatch(userByTokenFailure('something went wrong')));
  };
};