import {
  HANDLE_REGISTRATION_STEP, SELECT_REGISTRATION_TYPE, REQUEST_CREATE_USER,
  CREATE_USER_RECEIVED, CREATE_USER_FAILED, EDIT_USER,
  RESET_REGISTRATION_STEP, HANDLE_DYNAMIC_REGISTRATION_STAGE,
  REQUEST_ROUTE, ROUTE_SUCCESS, ROUTE_FAILURE
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  header: 'Create an Account',
  step: 1,
  nextStep: '1',
  stepStage: 'registration',
  confirmationCopy: {
    iconUrl: 'https://assets1.dailyburn.com/production/d2c/registration/confirmation/congratulations-icon.png',
    headline: 'Congratulations!',
    subHeadline: 'You just signed up for a free 30-day trial of Yoga Workouts. After the trial is over, you’ll be charged $9.99 a month.'
  },
  user: {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    facebookId: '',
    googleId: '',
    id: '',
    displayName: ''
  },
  promoCodeUser: {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    promo_code: '',
    partner: ''
  },
  registrationType: 'email', // default to email
  userSignedIn: false,
  apiError: null,
  loading: false,
  route: {
    1: {
      "stage": "registration",
      "next": 2
    },
    2: {
      "stage": "payment",
      "next": 3
    },
    3: {
      "stage": "download",
      "next": null
    }
  },
  coreRegStep: 0
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case SELECT_REGISTRATION_TYPE:
      return { ...state, registrationType: action.registrationType };
    case HANDLE_REGISTRATION_STEP: {
      return { ...state, step: state.step += 1 };
    }
    case HANDLE_DYNAMIC_REGISTRATION_STAGE: {
      const route = action.route;
      state.stepStage = route[state.nextStep]["stage"];
      const newHeader = getHeader(state.stepStage);
      // next refers to next step which we get as number type from server
      const next = route[state.nextStep]["next"];
      if (next != null) {
        state.nextStep = route[state.nextStep]["next"];
      }
      return { ...state, header: newHeader, coreRegStep: state.coreRegStep += 1 };
    }
    case REQUEST_CREATE_USER: {
      if (action.registrationType === 'email') {
        state.user.email = action.userToCreate.email;
        state.user.first_name = action.userToCreate.first_name;
        state.user.last_name = action.userToCreate.last_name;
      }
      return { ...state, registrationType: action.registrationType };
    }
    case CREATE_USER_RECEIVED: {
      state.user['id'] = action.data.id;
      state.user['displayName'] = action.data.displayName;
      state.user['email'] = action.data.identifier;
      if (state.registrationType === 'google') {
        state.user['googleId'] = action.data.googleId;
      } else if (state.registrationType === 'facebook') {
        state.user['facebookId'] = action.data.facebookId;
      }
      return { ...state, userSignedIn: true };
    }
    case CREATE_USER_FAILED:
      return { ...state, apiError: action.error, userSignedIn: false }
    case EDIT_USER:
      return { ...state, user: action.data };
    case RESET_REGISTRATION_STEP: {
      state = INITIAL_STATE;
      return state;
    }
    case REQUEST_ROUTE:
      return { ...state, loading: true };
    case ROUTE_SUCCESS:
      return { ...state, loading: false, route: action.data };
    case ROUTE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const getHeader = (stepStage) => {
  switch (stepStage) {
    case "registration":
      return 'Create an Account';
    case "payment":
      return 'Select Payment';
    default:
      return '';
  }
}