import {
  HANDLE_SUBS_CANCELLATION_STEP, REQUEST_CANCEL_SUBS, CANCEL_SUBS_SUCCESS,
  CANCEL_SUBS_FAILURE, SET_SELECTED_OPTION, SET_CANCEL_SUBS_REASON, CLEAR_CANCEL_SUBS_STATE
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  options: ["I don't have enough time to use Daily Burn.", "The cost of membership.", "The service is not what I expected.",
    "The workouts were too difficult.", "I’ve found a better way to stay fit.", "I’m having technical issues using the app.",
    "I need to change my billing information.", "Other"],
  selectedOption: '',
  step: 1,
  cancelReason: '',
  cancelErr: '',
  loading: false
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case HANDLE_SUBS_CANCELLATION_STEP:
      return { ...state, step: action.step };
    case SET_SELECTED_OPTION:
      return { ...state, selectedOption: action.option };
    case SET_CANCEL_SUBS_REASON:
      return { ...state, cancelReason: action.reason };
    case REQUEST_CANCEL_SUBS:
      return { ...state, loading: true };
    case CANCEL_SUBS_SUCCESS:
      return { ...state, loading: false };
    case CANCEL_SUBS_FAILURE:
      return { ...state, loading: false, cancelErr: action.error };
    case CLEAR_CANCEL_SUBS_STATE:
      return { ...state, loading: false, cancelErr: '', step: 1, selectedOption: '', cancelReason: '' };
    default:
      return state;
  }
};