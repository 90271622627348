import {
  SIGN_IN_SUCCESSFUL,
  SIGN_IN_ERROR,
  REQUEST_SIGN_IN,
  REQUEST_VERIFY_RECAPTCHA,
  VERIFY_RECAPTCHA_FAILURE,
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  email: '',
  password: '',
  validated: false,
  signinErr: null,
  loading: false,
  isSignedInSuccess: false
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case REQUEST_SIGN_IN:
      return { ...state, loading: true, signinErr: null };
    case SIGN_IN_SUCCESSFUL:
      return { ...state, signinErr: null, loading: false, validated: true, isSignedInSuccess: true };
    case SIGN_IN_ERROR:
      return { ...state, validated: false, signinErr: action.error, loading: false };
    case REQUEST_VERIFY_RECAPTCHA:
      return { ...state, loading: true, signinErr: null };
    case VERIFY_RECAPTCHA_FAILURE:
      return { ...state, validated: false, signinErr: action.error, loading: false };
    default:
      return state;
  }
};