function isStagingEnv() {
  return process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development';
}

export function getFbAppId(property) {
  switch (property) {
    case 'running':
      return process.env.REACT_APP_FACEBOOK_RUNNING_APP_ID;
    case 'yoga':
      return process.env.REACT_APP_FACEBOOK_YOGA_APP_ID;
    case 'at_home':
      return process.env.REACT_APP_FACEBOOK_CORE_APP_ID;
    default:
      return '';
  }
}

export function getMetadata(property) {
  switch (property) {
    case 'at_home':
      document.title = 'Daily Burn - Thousands of Workouts, Stream Videos Anywhere';
      document.querySelector('meta[name="description"]').setAttribute("content", "We are a health and fitness company that provides multi-platform workout videos and nutrition programs to millions of people.");
      document.querySelector('meta[name="keywords"]').setAttribute("content", "Daily Burn workout videos, workout videos, online workouts, online fitness programs, streaming workout videos");
      break;
    case 'running':
      document.title = 'Running by Daily Burn - Run Harder, Faster, Longer';
      document.querySelector('meta[name="description"]').setAttribute("content", "Find your inner runner. Download the Running app today for guided workouts led by your own, personal coach.");
      document.querySelector('meta[name="keywords"]').setAttribute("content", "daily burn running, daily burn running workout, running app, running videos, guided running app");
      break;
    case 'yoga':
      document.title = 'Yoga Workouts by Daily Burn - Guided Yoga for Every Body';
      document.querySelector('meta[name="description"]').setAttribute("content", "Experience the benefits of yoga right from your mobile device. The Yoga app from Daily Burn, download it today.");
      document.querySelector('meta[name="keywords"]').setAttribute("content", "daily burn yoga, daily burn yoga workouts, yoga workout app, yoga app, yoga workout videos");
      break;
    default:
      break;
  }
}

export function getAdjustLink(property) {
  switch (property) {
    case 'at_home':
      return process.env.REACT_APP_ADJUST_AT_HOME_APP_DOWNLOAD_LINK;
    case 'running':
      return process.env.REACT_APP_ADJUST_RUNNING_APP_DOWNLOAD_LINK;
    case 'yoga':
      return process.env.REACT_APP_ADJUST_YOGA_APP_DOWNLOAD_LINK;
    default:
      return '';
  }
}

export const getApiURL = () => {
  if (isStagingEnv()) {
    return process.env.REACT_APP_STAGING_API_URL;
  } else {
    return process.env.REACT_APP_PRODUCTION_API_URL;
  }
}

export const getPartnerPlanId = (params, property) => {
  // PROD PLAN IDs with Periodicity = Monthly
  const PROD_YogaPremiumPlanID = 660;
  const PROD_YogaSkimmPlanID = 661;
  const PROD_RunningPremiumPlanID = 664;
  const PROD_AtHomePremiumPlanID = 709;

  // STG PLAN IDs with Periodicity = Monthly
  const STG_YogaPremiumPlanID = 681;
  const STG_YogaSkimmPlanID = 682;
  const STG_RunningPremiumPlanID = 683;
  const STG_AtHomePremiumPlanID = 647;

  if (property) {
    switch (property) {
      case "yoga": {
        if (params.get("p_id")) {
          if (isStagingEnv()) {
            return STG_YogaSkimmPlanID;
          }
          return PROD_YogaSkimmPlanID;
        } else {
          if (isStagingEnv()) {
            return STG_YogaPremiumPlanID;
          }
          return PROD_YogaPremiumPlanID;
        }
      }
      case "running":
        if (isStagingEnv()) {
          return STG_RunningPremiumPlanID;
        }
        return PROD_RunningPremiumPlanID;
      case "at_home":
        if (isStagingEnv()) {
          return STG_AtHomePremiumPlanID;
        }
        return PROD_AtHomePremiumPlanID;
      default: {
        if (isStagingEnv()) {
          return STG_AtHomePremiumPlanID;
        }
        return PROD_AtHomePremiumPlanID;
      }
    }
  }

  return 0;
}

export const createPassportRedirectURL = (property, passport) => {
  switch (property) {
    case "yoga": {
      return '';
    }
    case "running": {
      return '';
    }
    case "at_home": {
      if (isStagingEnv()) {
        return process.env.REACT_APP_STG_CORE_APP_LINK + `app?passport=${passport}`
      }
      return process.env.REACT_APP_PROD_CORE_APP_LINK + `app?passport=${passport}`
    }
    default:
      return process.env.REACT_APP_PROD_CORE_APP_LINK + `app?passport=${passport}`;
  }
}

export const getPropertyDisplayName = (property) => {
  switch (property) {
    case 'running':
      return 'Running by Daily Burn';
    case 'yoga':
      return 'Yoga Workouts by Daily Burn';
    case 'at_home':
      return 'At Home Workouts by Daily Burn';
    default:
      return 'Daily Burn';
  }
}

export const deeplinkToProperty = (property, passport) => {
  switch (property) {
    case 'at_home':
      return `dailyburn://mobile/navigate/login?passport=${passport}`;
    case 'yoga':
      return `https://links.yoga.dailyburn.com/mobile/navigate/login?passport=${passport}`;
    case 'running':
      return `https://links.running.dailyburn.com/mobile/navigate/login?passport=${passport}`;
    default:
      return `https://portal.dailyburn.com?passport=${passport}`;
  }
}

export const getGoogleRecaptchaSiteKey = () => {
  return process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY;
}

export const xFetch = (url, method, body) => {
  const fetchDataObject = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }
  if (body) {
    fetchDataObject.body = JSON.stringify(body);
  }
  return fetch(`${getApiURL()}${url}`, fetchDataObject)
    .then(response => response.json())
    .then(json => {
      if (json.response.code !== 200) {
        throw json.response.message;
      }
      const { response } = json;
      return response;
    })
}

export const makeActionCreator = (type, ...argNames) => {
  return function (...args) {
    const action = { type }
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    })
    return action
  }
}


export const verifyGoogleRecaptcha = (token, property) => {
    fetch(`${getApiURL()}verify?property=${property}&response=${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(response => response.json())
    .then(json => json.success && json.score >= 0.5)
    .catch(error => false);
};
