// Index
export const RESET_STORE = "reset_store";

export const SET_INITIAL_APP_PROPERTIES = "set_initial_app_properties";
export const REQUEST_UUID = "request_uuid";
export const UUID_SUCCESS = "uuid_success";
export const UUID_FAILURE = "uuid_failure";
export const REQUEST_PLAN = "request_plan";
export const PLAN_SUCCESS = "plan_success";
export const PLAN_FAILURE = "plan_failure";
export const REQUEST_STYLIST = "request_stylist";
export const STYLIST_SUCCESS = "stylist_success";
export const STYLIST_FAILURE = "stylist_failure";
export const REQUEST_FETCH_USER = "request_fetch_user";
export const FETCH_USER_SUCCESS = "fetch_user_success";
export const FETCH_USER_FAILURE = "fetch_user_failure";
export const REQUEST_SUBSCRIPTION_STATUS = "request_fetch_user";
export const SUBSCRIPTION_STATUS_SUCCESS = "subscription_status_success";
export const SUBSCRIPTION_STATUS_FAILURE = "subscription_status_failure";
export const REQUEST_DECODE_PASSPORT = "request_decode_passport";
export const DECODE_PASSPORT_FAILURE = "decode_passport_failure";
export const USER_BY_TOKEN_FAILURE = "user_by_token_failure";
export const USER_BY_TOKEN_SUCCESS = "user_by_token_success";
export const UPDATE_PROMO_CODE = "update_promo_code";

// Home
export const SET_SESSION_FLAG = "set_session_flag";
export const SET_PURCHASED_FLAG = "set_purchased_flag";
export const SET_SESSION_USER = "set_session_user";
export const SET_SUBSCRIPTION_STATUS = "set_subscription_status";

// Landing
export const UPDATE_SCROLL_EVENT_FLAG = "update_scroll_event_flag";
export const SHOW_STICKY_CTA = "show_sticky_cta";
export const HIDE_STICKY_CTA = "hide_sticky_cta";

// Registration
export const HANDLE_REGISTRATION_STEP = "handle_registration_step";
export const SELECT_REGISTRATION_TYPE = "select_registration_type";
export const REQUEST_CREATE_USER = "request_create_user";
export const CREATE_USER_RECEIVED = "create_user_received";
export const CREATE_USER_FAILED = "create_user_failed";
export const CREATE_USER = "create_user";
export const EDIT_USER = "edit_user";
export const RESET_REGISTRATION_STEP = "reset_registration_step";
export const HANDLE_DYNAMIC_REGISTRATION_STAGE = "handle_dynamic_registration_stage";
export const REQUEST_ROUTE = "request_route";
export const ROUTE_SUCCESS = "route_success";
export const ROUTE_FAILURE = "route_failure";
export const REQUEST_VERIFY_RECAPTCHA = "request_verify_recaptcha";
export const VERIFY_RECAPTCHA_SUCCESS = "verify_recaptcha_success";
export const VERIFY_RECAPTCHA_FAILURE = "verify_recaptcha_failure";

// Registration Form
export const HANDLE_USER_INPUT = "handle_user_input";
export const FORM_VALIDATED = "form_validated";
export const EMAIL_REGISTRATION_SUCCESSFUL = "email_registration_successful";
export const EMAIL_REGISTRATION_ERROR = "email_registration_error";
export const PREFILL_REG_FORM = "prefill_reg_form";

// Registration Selector
export const REQUEST_SOCIAL_SSO = "request_social_sso";
export const SOCIAL_SSO_SUCCESSFUL = "social_sso_successful";
export const SOCIAL_SSO_ERROR = "social_sso_error";

// Payment Form
export const BT_HOSTEDFIELD_INSTANCE = "bt_hostedfield_instance";
export const BT_HOSTEDFIELD_INSTANCE_READY = "bt_hostedfield_instance_ready";
export const PAYMENT_METHOD_NONCE_RECEIVED = "payment_method_nonce_received";
export const REQUEST_BRAINTREE_CLIENT_TOKEN = "request_braintree_client_token";
export const BRAINTREE_CLIENT_TOKEN_RECEIVED = "braintree_client_token_received";
export const BRAINTREE_CLIENT_TOKEN_FAILED = "braintree_client_token_failed";
export const REQUEST_JOIN_PLAN = "request_join_plan";
export const JOIN_PLAN_RECEIVED = "join_plan_received";
export const JOIN_PLAN_FAILED = "join_plan_failed";
export const CLEAR_PAYMENT_METHOD = "clear_payment_method";
export const SET_PATH_NAME = "set_location_path_name";

export const REQUEST_PM_UPDATE = "request_pm_update";
export const PM_UPDATE_SUCCESS = "pm_update_success";
export const PM_UPDATE_FAILED = "pm_update_failed";

export const REQUEST_REDIRECT = "request_redirect";

// Sign In
export const HANDLE_SIGN_IN_INPUT = "handle_sign_in_input";
export const REQUEST_SIGN_IN = "request_sign_in";
export const SIGN_IN_SUCCESSFUL = "sign_in_successful";
export const SIGN_IN_ERROR = "sign_in_error";

// Payment
export const RENDER_CONFIRMATION = "render_confirmation";

// Account Summary
export const REQUEST_PAYMENT_METHOD = "request_payment_method";
export const PAYMENT_METHOD_SUCCESS = "payment_method_success";
export const PAYMENT_METHOD_FAILURE = "payment_method_failure";

// Cancel Subscription
export const HANDLE_SUBS_CANCELLATION_STEP = "handle_cancellation_step";
export const REQUEST_CANCEL_SUBS = "request_cancel_subs";
export const CANCEL_SUBS_SUCCESS = "cancel_subs_success";
export const CANCEL_SUBS_FAILURE = "cancel_subs_failure";
export const SET_SELECTED_OPTION = "set_selected_option";
export const SET_CANCEL_SUBS_REASON = "set_cancel_subs_reason";
export const CLEAR_CANCEL_SUBS_STATE = "clear_cancel_subs_state";

// HollandAmerica Partnership
// Registration Form
export const SET_PARTNER = "set_partner";
export const PROMO_USER_INPUT = "promo_user_input";
export const PROMO_FORM_VALIDATED = "promo_form_validated";
export const PROMO_REGISTRATION_SUCCESSFUL = "promo_registration_successful";
export const PROMO_REGISTRATION_ERROR = "promo_registration_error";
export const SCROLL_TO_PROMO_FORM = "scroll_to_promo_form";
export const SCROLLED_SUCCESS = "scrolled_success";
export const REQUEST_SWEEPS_CONSENT = "request_sweeps_consent";
export const SWEEPS_CONSENT_SUCCESS = "sweeps_consent_success";
export const SWEEPS_CONSENT_FAILURE = "sweeps_consent_failure";
export const RESET_PROMO_STATE = "reset_promo_state";

// Forgot Password
export const HANDLE_RP_EMAIL_INPUT = "handle_rp_email_input"
export const RP_EMAIL_FORM_VALIDATED = "rp_email_form_validated"
export const REQUEST_FORGOT_PASSWORD = "request_forgot_password";
export const FORGOT_PASSWORD_SUCCESS = "forgot_password_success";
export const FORGOT_PASSWORD_FAILURE = "forgot_password_failure";
export const RESET_FP_STATE = "reset_fp_state";

// Reset Password
export const HANDLE_RP_PASSWORD_INPUT = "handle_rp_password_input"
export const RP_PASSWORD_FORM_VALIDATED = "rp_password_form_validated"
export const SET_HASH_ID = "set_hash_id"
export const REQUEST_RESET_PASSWORD = "request_reset_password"
export const RESET_PASSWORD_SUCCESS = "reset_password_success"
export const RESET_PASSWORD_FAILURE = "reset_password_failure"

// Code Redeem
export const REQUEST_PROMO_CODE_VERIFICATION = "request_promo_code_verification";
export const PROMO_CODE_VERIFICATION_SUCCESS = "promo_code_verification_success";
export const PROMO_CODE_VERIFICATION_FAILURE = "promo_code_verification_failure";
