import {
  HANDLE_REGISTRATION_STEP,
  REQUEST_CREATE_USER,
  CREATE_USER_RECEIVED,
  CREATE_USER_FAILED,
  REQUEST_PROMO_CODE_VERIFICATION,
  PROMO_CODE_VERIFICATION_SUCCESS,
  PROMO_CODE_VERIFICATION_FAILURE
} from "../actionCreators/ActionTypes";

const INITIAL_STATE = {
  user: {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    promo_code: "",
    partner: ""
  },
  registrationType: "email", // default to email
  userSignedIn: false,
  error: "",
  loading: false,
  promoCodeStep: 0,
  promoDuration: 60
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case HANDLE_REGISTRATION_STEP: {
      return { ...state, promoCodeStep: (state.promoCodeStep += 1) };
    }
    case REQUEST_CREATE_USER: {
      if (action.registrationType === "email") {
        state.user.email = action.userToCreate.email;
        state.user.first_name = action.userToCreate.first_name;
        state.user.last_name = action.userToCreate.last_name;
      }
      return {
        ...state,
        registrationType: action.registrationType,
        loading: true
      };
    }
    case CREATE_USER_RECEIVED: {
      state.user["id"] = action.data.id;
      state.user["displayName"] = action.data.displayName;
      state.user["email"] = action.data.identifier;
      if (state.registrationType === "google") {
        state.user["googleId"] = action.data.googleId;
      } else if (state.registrationType === "facebook") {
        state.user["facebookId"] = action.data.facebookId;
      }
      return { ...state, error: "", userSignedIn: true, loading: false };
    }
    case CREATE_USER_FAILED:
      return {
        ...state,
        error: action.error,
        userSignedIn: false,
        loading: false
      };
    case REQUEST_PROMO_CODE_VERIFICATION:
      return { ...state, loading: true };
    case PROMO_CODE_VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        promoDuration: action.duration
      };
    case PROMO_CODE_VERIFICATION_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
