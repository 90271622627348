// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';

import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './utils/configureStore';
import './fonts/CircularStd-Bold.otf'
import './fonts/CircularStd-Book.otf'


import { render } from "react-dom";

const rootElement = document.getElementById("root");
render(
    <Provider store={configureStore()}>
      <App />
    </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
