import {
  SET_SESSION_FLAG,
  SET_PURCHASED_FLAG,
  SET_SESSION_USER,
  SET_SUBSCRIPTION_STATUS
} from "./ActionTypes";

import { makeActionCreator } from "../utils/utils";

export const setSessionFlag = makeActionCreator(SET_SESSION_FLAG, 'sessionProperty');
export const setPurchasedFlag = makeActionCreator(SET_PURCHASED_FLAG);
export const setSessionUser = makeActionCreator(SET_SESSION_USER, 'user', 'sessionType');
export const setSubscriptionStatus = makeActionCreator(SET_SUBSCRIPTION_STATUS, 'data');
